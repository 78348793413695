@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-neutral-800 text-stone-200;
}

/* Keyframes für den Fall der Schneeflocken */
@keyframes fall {
  0% {
    transform: translateY(-100px) translateX(0);
  }
  100% {
    transform: translateY(100vh) translateX(var(--horizontal-speed));
  }
}

/* Keyframe für den Glitzereffekt der Schneeflocken */
@keyframes sparkle {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

/* Schneeflocken-Stil */
.snowflake {
  @apply absolute rounded-full bg-white opacity-80;
  animation-name: fall, sparkle;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

/* Schneeflocken mit unterschiedlicher Größe und Fallgeschwindigkeit */
.snowflake.small {
  width: 3px;
  height: 3px;
  animation-duration: 8s; /* kürzere Zeit für kleinere Schneeflocken */
}

.snowflake.medium {
  width: 5px;
  height: 5px;
  animation-duration: 10s; /* Standard-Fallgeschwindigkeit */
}

.snowflake.large {
  width: 8px;
  height: 8px;
  animation-duration: 12s; /* längere Zeit für größere Schneeflocken */
}
